import React, { useState } from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { H2 } from '../components/common/H.style';
import { H3 } from '../components/common/H.style';
import { H4 } from '../components/common/H.style';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import ButtonList from '../components/ButtonList';
import Link from '../components/Link';

import zu1 from '../assets/img/PR/20230425/zu1.png';
import zu2 from '../assets/img/PR/20230425/zu2.png';
import zu3 from '../assets/img/PR/20230425/zu3.png';

const Wrapper = styled.div`
  margin: 50px 0;
`;

const PR20230425 = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="プレスリリース">
      <Wrapper>
        <Flex
          wrap="true"
          justifyBetween
          css={`
            margin: 0;
            padding: 0;
          `}
        >
          <FlexItem>
            <P
              css={`
                text-align: left;
              `}
            >
              報道関係者各位
              <br />
              情報提供資料
            </P>
          </FlexItem>
          <FlexItem>
            <P
              css={`
                text-align: right;
              `}
            >
              2023年4月25日
              <br />
              ヒラハタクリニック
            </P>
          </FlexItem>
        </Flex>
      </Wrapper>
      <div>
        <H3>
          コロナ後遺症に呼吸リハビリオンライン指導が有効な可能性
          <br />
          スマートウォッチでコロナ後遺症患者への効果検証
        </H3>
        <P>～リハビリテーション医療DX研究会　第１回学術集会にて発表～</P>
        <P>
          　ヒラハタクリニック（東京都渋谷区　代表：平畑光一）とのざき鍼灸治療院（千葉県流山市　代表：野崎真治）は、新井田厚司講師（東京大学医科学研究所附属ヒトゲノム解析センターゲノム医科学分野）の協力のもと、コロナ後遺症の患者に対する呼吸リハビリテーションのオンライン指導(呼吸リハビリオンライン指導)の効果の検証研究を行いました。質問紙調査より、コロナ後遺症の特徴である倦怠感や呼吸苦、思考力低下、不眠などの症状が改善する可能性があることがわかりました。またスマートウォッチによる検証から一部の患者に不眠の改善と活動量の上昇が観察され、併せて呼吸リハビリオンライン指導のコロナ後遺症への効果を示唆する結果が得られました。今回の研究成果は、リハビリテーション医療DX研究会　第1回学術集会（2023年04月22日～23日・沖縄開催）にて報告しました。
        </P>
        <H4>はじめに</H4>
        <P>
          　現在、新型コロナウイルス（COVID-19）に感染した後に後遺症（以下コロナ後遺症）に苦しむ方が世界中で増えています。コロナ後遺症は「Long-COVID」と呼ばれ、通常の生活が営めなくなったり、失職するなどして米国では約31兆円の経済損失が推定されています。その点から鑑みれば我が国においても、非常に大きな経済損失が起きている可能性があります。また、オランダの統計によるとCOVID-19に起因する後遺症有病率は12・7％であり1）わが国でも感染から1年６カ月経過した時点で感染者の4人に一人が何らかの後遺症状を抱えているとも報告されています。2）コロナ後遺症では症状や期間により個人差がありますが、その多くは倦怠感（だるさ、疲労感）や息苦しさ、思考力の低下（ブレインフォグ）や不眠などでQOL（生活の質）が低下し、症状が重い場合は家で寝たきりとなり、医療へのアクセスや就労も出来ず、大きな社会問題になっています。
          <br />
          　現在、コロナ後遺症に対する効果的な治療法は確立していませんが、先行研究や臨床上の所見から、後遺症状には感染による肺の機能障害や微小血栓などが原因の「組織の酸素不足」が強く関係している可能性が高く、酸素供給量を増やすための呼吸器リハビリテーション法（呼吸リハビリ）が有効と考えられます。従来の自己で行う呼吸リハビリとしては、両手を組み肩関節屈伸運動と深呼吸を行うことで大きな換気量が得られるとされているシルベスター法などが知られていました。しかし重症の患者では実施が難しく、また椅子に座って行う胸郭可動域拡大訓練、筋力トレーニングなども推奨されますが、いずれも中程度以上の患者には負荷が強すぎ、実施不可能でした。私たちは上記の問題点を改善し、重症度に併せて無理なく実施が可能な呼吸リハビリ方法を開発し、呼吸リハビリオンライン指導を実施しています。これまでにのべ700人のコロナ後遺症患者が参加し「呼吸が楽になった」「倦怠感が少なくなった」「活動量が増えた」「よく眠れるようになった」「寝たきりでも自宅でできるので助かる」など私たちの呼吸リハビリ方法を支持する多数の患者の声を得ているものの、その効果の科学的検証はなされていませんでした。そこで本研究では、質問調査紙及びスマートウォッチを活用して呼吸リハビリオンライン指導のコロナ後遺症への効果の立証を試みました。
          <br />
        </P>
        <P>
          1）Ballering, Aranka V., et al. "Persistence of somatic symptoms after COVID-19 in the
          Netherlands: an observational cohort study." The Lancet 400.10350 (2022): 452-461.
          <br />
          2）Morioka, S., et al. "Epidemiology of post-COVID conditions beyond 1 year: a
          cross-sectional study." Public Health 216 (2023): 39-44.
        </P>

        <H4>方法</H4>
        <P>
          　COVID-19感染後6カ月経過しても症状の残存している4人の患者さんを対象者としてガーミン社製スマートウォッチを装着してもらい、週一回のオンライン呼吸リハビリセッションに最低4回参加してもらいました。また、セッション開始前(セッション当日のセッションが始まる前まで）、並びに、呼吸リハビリ指導開始一週間前及び最後のセッションから一週間後の計最低6回質問紙調査に答えてもらい(図1)、以下の項目のデータを取得しました。
        </P>
        <P>
          質問調査紙:
          <br />
          疲労, 発熱, 頭痛, 体の痛み, 息苦しさ, 咳, 動悸, 食欲不振, 不眠, うつ,思考力の低下, 脱毛,
          嗅覚障害, 味覚障害について11段階の
          NRS(患者の感じている症状の主観的11段階評価,PS(筋痛性脳脊髄炎／慢性疲労症候群の重症度の指標）,
          PEM(心身の労作後に症状が悪化し、機能が低下する現象)の有無
          <br />
          <br />
          スマートウォッチ:
          <br />
          平均心拍数, 最大心拍数, 最小心拍数, 安静心拍数,睡眠時間, レム睡眠時間,
          血中酸素濃度,歩数、ストレス (それぞれ一日の平均値)
        </P>
        <P
          css={`
            text-align: center;
          `}
        >
          <Img
            src={zu1}
            css={`
              width: 50%;
            `}
          />
          <br />
          図1: データ取得のタイミング
        </P>

        <H4>結果</H4>
        <P>
          　質問調査紙による結果により思考力の低下、うつ、不眠、呼吸苦、疲労等の自覚的症状の改善や(図２)スマートウォッチのデータから一部の患者にレム睡眠時間の低下、すなわち不眠の改善や活動量の上昇等が観察され（図3）、呼吸リハビリの継続によりコロナ後遺症の諸症状が改善されることが示唆されました。
        </P>
        <Wrapper>
          <Flex
            wrap="true"
            justifyAround
            css={`
              margin-bottom: 30px;
              text-align: center;
            `}
          >
            <FlexItem>
              <Img src={zu2} />
              <br />
              図2: 質問調査紙からの結果
            </FlexItem>
            <FlexItem>
              <Img src={zu3} />
              <br />
              図3: スマートウォッチデータからの結果
            </FlexItem>
          </Flex>
        </Wrapper>

        <H4>まとめと今後の展望</H4>
        <P>
          　呼吸リハビリオンライン指導によりコロナ後遺症の特徴である倦怠感や息苦しさ、思考力低下、不眠などの症状が改善する可能性が示されました。今後は対象者を増やして上記傾向の確認をしていきます。
        </P>

        <H4>取材依頼・お問い合わせ先</H4>
        <P>
          会社名　　：ヒラハタクリニック
          <br />
          担当　　　：平畑光一
          <br />
          電話　　　：03-3400-3288
          <br />
          メール　　：info@hirahata-clinic.or.jp
          <br />
          所在地　　：〒150-0002 東京都渋谷区渋谷1－24－6　マトリクス・ツービル９F
          <br />
          本件に関するより詳しい内容をご希望でしたら、取材お申込みを随時受け付けておりますので、ぜひお問合せください。
        </P>
      </div>
    </SubLayout>
  );
};

export default PR20230425;
