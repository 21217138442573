import React from 'react';
import Flex from 'styled-flex-component';

import Button from './Button';

const ButtonList = ({ size, list = [] }) => {
  const btn = {
    w: (size === 'sm' && 260) || 280,
    h: (size === 'sm' && 70) || 100,
    fontSize: (size === 'sm' && 14) || 16,
    margin: '5px',
  };
  return (
    <Flex
      center
      css={`
        margin-top: 20px;
        margin-bottom: 20px;
      `}
    >
      <Flex justifyAround={size !== 'sm'} column={size === 'sm'}>
        {list.map(({ link, title, external }) => (
          <Button
            external={!!external}
            key={title}
            link={link}
            title={title}
            width={btn.w}
            height={btn.h}
            fontSize={btn.fontSize}
            margin={btn.margin}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default ButtonList;
