import React, { useState } from 'react';
import styled from 'styled-components';

import { useTrackedState } from '../lib/state';

import Header from './Header';
import Footer from './Footer';
import SideMenu from './SideMenu';

import { GlobalStyle } from './layouts/Layout.style';
import { H2, H2Background } from './common/H.style';
import MainContainer from './layouts/MainContainer';
import SEO from './Seo';

const SubPageTitleBar = styled(H2Background)`
  margin-top: 20px;
`;

const SubLayout = ({ children, topTitle }) => {
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const { size } = useTrackedState();
  return (
    <>
      <GlobalStyle />
      <SEO pageTitle={topTitle} />
      <SideMenu size={size} open={openSideMenu} onClose={() => setOpenSideMenu(false)} />
      <Header size={size} onOpenSideMenu={() => setOpenSideMenu(true)} />
      <SubPageTitleBar>
        <MainContainer size={size}>
          <H2 size={size}>{topTitle}</H2>
        </MainContainer>
      </SubPageTitleBar>
      <MainContainer size={size}>
        <main>{children}</main>
      </MainContainer>
      <Footer size={size} />
    </>
  );
};

export default SubLayout;
